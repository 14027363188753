import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl = environment.baseUrl;
  public prefix = "/auth/v1";

  constructor(private http: HttpClient) {}

  login(email: string, password: string) {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return this.http
      .post<any>(this.baseUrl + this.prefix + `/login`, formData)
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  forgotPassword(email: string) {
    const formData = new FormData();
    formData.append("email", email);
    return this.http
      .post<any>(this.baseUrl + this.prefix + `/user/forgot-password`, formData)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  setupPassword(data: any) {
    return this.http
      .put<any>(this.baseUrl + this.prefix + `/user/reset-password`, data)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  register(data: any) {
    return this.http.post(this.baseUrl + this.prefix + `/user/register`, data);
  }

  logout() {
    const itemsToRemove = [
      "jwt",
      "currentUser",
      "roles",
      "permissions",
      "dmsPermissions",
      "settings",
    ];
    itemsToRemove.forEach((item) => {
      localStorage.removeItem(item);
    });
  }

  getInfoAPK(url) {
    return this.http.get(url).map((response: JSON) => {
      return response;
    });
  }
}
