import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService } from '../../core/services';
import { AppHelpers } from '../../core/helpers';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthService],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  linkApk: any = environment.linkApk;
  linkIos: any = environment.linkIos;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public appHelpers: AppHelpers
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });

    this.authService.logout();
    this.returnUrl =
      this.activatedRoute.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      document.getElementById('loading').classList.add('active');
      this.authService
        .login(this.f.email.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          (data) => {
            document.getElementById('loading').classList.remove('active');
            if (data.data) {
              localStorage.setItem('jwt', data.data.token);
              localStorage.setItem(
                'currentUser',
                JSON.stringify(data.data.user)
              );
              localStorage.setItem('roles', JSON.stringify(data.data.roles));
              localStorage.setItem(
                'permissions',
                JSON.stringify(data.data.permissions)
              );

              this.router.navigate([this.returnUrl]);
            }
          },
          (error) => {
            document.getElementById('loading').classList.remove('active');
            this.appHelpers.showToast('error', error.error.message);
          }
        );
    }
  }
}
