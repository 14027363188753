import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subscription } from 'rxjs';
import { environment } from '@environment';

import { AppHelpers } from '../../../core/helpers';
import { DataSharingService } from '../../../shared/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser: any;
  navItemsList: any;
  bsModalRef: BsModalRef;
  profileAvatarPath: string = environment.default_avatar;
  subscriptionUserProfile: Subscription;
  roleCode: any;

  constructor(
    public appHelpers: AppHelpers,
    private dataSharingService: DataSharingService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const roleList = JSON.parse(localStorage.getItem('roles'));
    this.roleCode = roleList[0].cd;
  }

  ngOnInit() {
    this.navItemsList = [
      {
        title: 'Operation',
        show:
          this.appHelpers.hasPermission('viewInfoRouteV2') ||
          this.appHelpers.hasPermission('viewOrderV2') ||
          this.appHelpers.hasPermission('viewWeighing'),
        hasDropdown: true,
        subMenu: [
          {
            title: 'Chuyến hàng',
            routerLink: ['/operation/shipment'],
            show: this.appHelpers.hasPermission('viewInfoRouteV2'),
            icon: 'fas fa-route',
          },
          {
            title: 'Đơn hàng',
            routerLink: ['/operation/order-info'],
            show: this.appHelpers.hasPermission('viewOrderV2'),
            icon: 'fas fa-shopping-cart',
          },
          {
            title: 'Cầu cân',
            routerLink: ['/accounting/weight'],
            show: this.appHelpers.hasPermission('viewWeighing'),
            icon: 'fas fa-dumbbell',
          },
        ],
      },
      {
        title: 'Reports',
        show:
          this.appHelpers.hasPermission('viewReportOrder') ||
          this.appHelpers.hasPermission('viewReportShipment') ||
          this.appHelpers.hasPermission('viewReportDifference') ||
          this.appHelpers.hasPermission('viewReportTruckEffective') ||
          this.appHelpers.hasPermission('viewReportTruckSm') ||
          this.appHelpers.hasPermission('viewReportTimeWork') ||
          this.appHelpers.hasPermission('viewReportDriverStatus'),
        hasDropdown: true,
        subMenu: [
          {
            title: 'Báo cáo chuyến hàng',
            routerLink: ['/report/shipments'],
            show: this.appHelpers.hasPermission('viewReportShipment'),
            icon: 'fas fa-cubes',
          },
          {
            title: 'Báo cáo đơn hàng',
            routerLink: ['/report/orders'],
            show: this.appHelpers.hasPermission('viewReportOrder'),
            icon: 'fas fa-cubes',
          },
          {
            title: 'Báo cáo chênh lệch bồn',
            routerLink: ['/report/warehouse'],
            show: this.appHelpers.hasPermission('viewReportDifference'),
            icon: 'fas fa-cubes',
          },
          {
            title: 'Báo cáo hiệu quả xe giao hàng',
            routerLink: ['/report/effective'],
            show: this.appHelpers.hasPermission('viewReportTruckEffective'),
            icon: 'fas fa-cubes',
          },
          {
            title: 'Báo cáo tình trạng xe',
            routerLink: ['/report/report-trucks'],
            show: this.appHelpers.hasPermission('viewReportTruckSm'),
            icon: 'fas fa-cubes',
          },

          {
            title: 'Báo cáo thời gian làm việc',
            routerLink: ['/report/working-driver'],
            show: this.appHelpers.hasPermission('viewReportTimeWork'),
            icon: 'fas fa-cubes',
          },
          {
            title: 'Báo cáo tình trạng tài xế',
            routerLink: ['/report/report-drivers'],
            show: this.appHelpers.hasPermission('viewReportDriverStatus'),
            icon: 'fas fa-cubes',
          },
          {
            title: 'Báo cáo cấp dầu',
            routerLink: ['/report/report-bills'],
            show: this.appHelpers.hasPermission('viewReportOilBill'),
            icon: 'fas fa-cubes',
          },
          {
            title: 'Báo cáo chi phí phát sinh',
            routerLink: ['/report/report-incrurred'],
            show: this.appHelpers.hasPermission('viewReportCostIncurred'),
            icon: 'fas fa-cubes',
          },
          {
            title: 'Báo cáo tổng hợp xe tải',
            routerLink: ['/report/report-general-truck'],
            show: this.appHelpers.hasPermission('viewReportGeneralTruck'),
            icon: 'fas fa-cubes',
          },
          {
            title: 'Báo cáo tạm ứng nhiên liệu',
            routerLink: ['/report/report-fuel-advance'],
            show: this.appHelpers.hasPermission('viewReportFuelAdvance'),
            icon: 'fas fa-cubes',
          },
        ],
      },
      {
        title: 'Đội Xe',
        show:
          this.appHelpers.hasPermission('viewDriverOff') ||
          this.appHelpers.hasPermission('viewOilBill') ||
          this.appHelpers.hasPermission('viewAnnouncement') ||
          this.appHelpers.hasPermission('viewCostIncurred'),
        hasDropdown: true,
        subMenu: [
          {
            title: 'Tài xế nghỉ phép',
            routerLink: ['/accounting/offday'],
            show: this.appHelpers.hasPermission('viewDriverOff'),
            icon: 'fas fa-calendar',
          },
          {
            title: 'Phiếu cấp dầu',
            routerLink: ['/accounting/billing'],
            show: this.appHelpers.hasPermission('viewOilBill'),
            icon: 'fas fa-money-check-alt',
          },
          {
            title: 'Thông báo & cảnh báo',
            routerLink: ['/accounting/announce'],
            show: this.appHelpers.hasPermission('viewAnnouncement'),
            icon: 'fas fa-bell',
          },
          {
            title: 'Chi phí phát sinh',
            routerLink: ['/accounting/costs'],
            show: this.appHelpers.hasPermission('viewCostIncurred'),
            icon: 'fas fa-file-invoice-dollar',
          },
        ],
      },
      {
        title: 'Admin',
        routerLink: ['/admin'],
        show: true,
        hasDropdown: false,
      },
    ];

    this.subscriptionUserProfile =
      this.dataSharingService.currentUserProfile.subscribe((userProfile) => {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

        this.profileAvatarPath = userProfile['avatar_thumb']
          ? environment.url_avatar + userProfile['avatar_thumb']
          : this.profileAvatarPath;
      });
  }
}
